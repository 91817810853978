import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import axios from "axios";
import sanityClient from "@sanity/client";
import sanityImageBuilder from "@sanity/image-url";
import PaypalExpressButton from "react-paypal-express-checkout";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PageTitle from "../components/PageTitle";

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: "",
  useCdn: true, // `false` if you want to ensure fresh data
});
const imageBuilder = sanityImageBuilder(client);
const paypalClient = {
  sandbox: process.env.GATSBY_PAYPAL_SANDBOX_ID,
  production: process.env.GATSBY_PAYPAL_PRODUCTION_ID,
};

const ClassesPage = ({ data }) => {
  const [classes, setClasses] = useState();
  const [purchaseStatus, setPurchaseStatus] = useState();

  useEffect(() => {
    client
      .fetch(
        '*[_type == "class" && date >= $currentDate] {title, date, image, description, cost, _id}',
        { currentDate: new Date() }
      )
      .then((allClasses) => {
        setClasses(allClasses);
      });
  }, []);

  const purchaseSuccess = (payment, exerciseClass) => {
    // Congratulation, it came here means everything's fine!
    axios
      .post("/.netlify/functions/signup", {
        payment: payment,
        exerciseClass: exerciseClass,
      })
      .then((res) => {
        setPurchaseStatus([
          true,
          "Your payment succeeded, thanks for signing up!",
        ]);
      })
      .catch((err) => {
        setPurchaseStatus([false, "There was an error signing you up."]);
      });
  };

  const purchaseError = (err) => {
    setPurchaseStatus([false, err]);
  };

  return (
    <Layout>
      <SEO
        title="Classes"
        keywords={[`health`, `fitness`, `classes`, `class`]}
      />
      <PageTitle
        image={data.settings.headerImage.asset.fixed}
        title="Classes"
      />
      <div className="md:w-3/4 w-full mx-auto px-6 py-12">
        {purchaseStatus ? (
          <section
            className={`pb-10 text-xl ${
              purchaseStatus[0] ? "text-green-700" : "text-red-700"
            }`}
          >
            {purchaseStatus[1]}
          </section>
        ) : null}
        {classes ? (
          <section className="flex flex-row flex-wrap justify-left -mx-3">
            {classes.length > 0 ? (
              classes.map((exerciseClass) => {
                const exerciseClassDate = new Date(exerciseClass.date);
                const exerciseClassId = exerciseClass._id;
                return (
                  <div
                    className="flex-grow-0 md:w-1/2 w-full whitespace-normal px-3 mb-6"
                    key={exerciseClassId}
                  >
                    <div className="flex flex-col border-standard">
                      {exerciseClass.image ? (
                        <img
                          alt="Exercise class"
                          style={{ height: "300px", objectFit: "cover" }}
                          src={imageBuilder
                            .image(exerciseClass.image)
                            .height(800)
                            .url()}
                        />
                      ) : (
                        <div
                          style={{ height: "300px" }}
                          className="bg-gray-300 w-full"
                        ></div>
                      )}
                      <div className="p-6">
                        <h2 className="font-serif text-3xl leading-tight">
                          {exerciseClass.title}
                        </h2>
                        <p className="text-gray-700 my-2">
                          {exerciseClass.description}
                        </p>
                        <p className="text-base opacity-75 mt-1">
                          Date:
                          <span className="text-primary mx-1">
                            {exerciseClassDate.toLocaleString()}
                          </span>
                        </p>
                        <p className="text-base opacity-75 mt-1">
                          Price:
                          <span className="text-primary mx-1">
                            {exerciseClass.cost
                              ? `$${exerciseClass.cost}`
                              : "Free!"}
                          </span>
                        </p>
                        <div className="mt-3 sm:w-1/2 w-full">
                          <PaypalExpressButton
                            env={
                              process.env.NODE_ENV === "production"
                                ? "production"
                                : "sandbox"
                            }
                            client={paypalClient}
                            currency={"USD"}
                            total={exerciseClass.cost}
                            style={{
                              size: "responsive",
                              color: "gold",
                              shape: "rect",
                              label: "pay",
                              tagline: false,
                            }}
                            onSuccess={(payment) =>
                              purchaseSuccess(payment, exerciseClass)
                            }
                            onError={purchaseError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-lg">
                There are no classes available at this time.
              </div>
            )}
          </section>
        ) : (
          <section className="text-lg opacity-75">
            Loading classes &hellip;
          </section>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ClassesPageQuery {
    settings: sanitySettings {
      headerImage: pageHeaderClasses {
        asset {
          fixed(width: 1920, height: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`;

export default ClassesPage;
